<template>
  <div>
    <!-- 公告 header -->
    <div class="indexUse_header">
      <div class="wrap headerBox">
        <div class="logo" @click="$router.push('/home/check/index')">
          <img src="@/assets/image/lianzhongjiankang.jpg" alt />
          联众健康
        </div>
        <div class="nav">
          <div @click="$router.push('/home/check/index')">首页</div>
          <div
            @mouseover="headerTab = 1"
            @mouseout="headerTab = -1"
            @click="commitHandle(0)"
            :class="{userArrActives : headerTab ==1}"
          >个人中心</div>
          <div
            @mouseover="headerTab = 3"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==3}"
          >
            消息
            <img v-show="headerTab != 3" src="@/assets/image/top_Open_icon@2x.png" alt />
            <img v-show="headerTab == 3" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 3"></i>
            <div v-show="headerTab == 3" class="server news">
              <p
                class="one"
                @click="lookMessage"
                v-if="messages && messages.length"
              >{{ messages[0].title }}: {{ messages[0].content}}</p>
              <p v-if="messages.length === 0">消息提示: 暂无更多消息</p>
              <p class="goAllNews" @click="lookMessage">查看全部消息</p>
            </div>
          </div>
        </div>
        <div
          class="ffr"
          @mouseover="headerTab = 4"
          @mouseout="headerTab = -1"
          :class="{userArrActives : headerTab ==4}"
        >
          <div class="ffrs">
            {{ userlist.user_login }}
            <img
              v-show="headerTab != 4"
              src="@/assets/image/top_Open_icon@2x.png"
              alt
            />
            <img v-show="headerTab == 4" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 4"></i>
            <div v-show="headerTab == 4" class="server">
              <div
                @click="changeLandPage(v)"
                @mouseover="userArrActive = i"
                @mouseout="userArrActive = -1"
                :class="{userArrActive : userArrActive ==i}"
                v-for="(v,i) in myArr"
                :key="i"
                class="list"
              >
                <img v-show="userArrActive ==i" :src="v.img1" alt />
                <img v-show="userArrActive !=i" :src="v.img2" alt />
                {{v.txt}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indexUse_header_min">
      <div class="wrap">
        <img
          v-if="userlist.avatar == ''"
          class="myHeaderImg fl"
          src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
        />
        <img v-else :src="userlist.avatar" class="myHeaderImg fl" />
        <div class="fl myName">
          <p>复核人员</p>
          <p>
            <img @click="commitHandle(1)" src="@/assets/image/top_Basicinformation_icon@2x.png" alt />
          </p>
        </div>
        <div class="fr fns fn">
          <p @click="commitHandle(2)">
            <img src="@/assets/image/top_wallet_icon@2x.png" alt />我的钱包
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMessageList } from "@/request/personnel";

export default {
  props: ["userlist"],
  data() {
    return {
      headerTab: -1,
      nav: -1,
      myArr: [
        {
          txt: "退出",
          img1: require("@/assets/image/top_Open_Signout_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Signout@2x.png")
        }
      ],
      userArrActive: -1,
      messages: ""
    };
  },
  created() {
    getMessageList({
      token: this.$token,
      type: 0 // 0代表获取系统消息
    }).then(res => {
      this.messages = res.data.data;
    });
  },
  methods: {
    commitHandle(num) {
      this.$emit("changeState", num);
    },
    changeLandPage(v) {
      if (v.txt === "退出") {
        this.$router.push("/land");
        localStorage.removeItem("lzyyToken");
      }
    },
    lookMessage() {
      this.$emit("changeState", 6);
    }
  }
};
</script>
<style lang="less" scoped>
// @import "./index";
.indexUse_header {
  cursor: Pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  line-height: 66px;
  background: white;
  z-index: 9;
  .headerBox {
    display: flex;
    position: relative;
  }
  .logo {
    font-size: 24px;
    img {
      width: 44px;
      height: 44px;
      float: left;
      margin-top: 10px;
      margin-right: 12px;
    }
  }
  .nav {
    display: flex;
    margin-left: 110px;
  }
  .nav > div {
    text-align: center;
    width: 120px;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 50px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
  }
  .server {
    position: absolute;
    top: 66px;
    width: 176px;
    left: -28px;
    z-index: 2;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
  }
  .server > .list {
    color: #000;
    cursor: pointer;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }
  }
  .userArrActives {
    color: #4eeec8;
  }
  .userArrActive {
    color: #4eeec8 !important;
  }
  .news {
    color: #000;
    width: 364px;
    // height: 150px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    p {
      background: #f5f5f5;
      text-align: left;
      font-size: 14px;
      height: 42px;
      line-height: 42px;
      padding-left: 10px;
      margin-bottom: 2px;
      cursor: pointer;
    }
    .goAllNews {
      margin-top: 10px;
      background: white;
      color: #4eeec8;
      text-align: center;
    }
  }
  .ffr {
    position: absolute;
    top: 0;
    right: 0;
    img {
      vertical-align: middle;
    }
    > .ffrs {
      position: relative;
    }
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 22px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
    .server {
      text-align: center;
      left: -58px !important;
    }
  }
}

// header min
.indexUse_header_min {
  margin-top: 68px;
  height: 120px;
  background: #4eeec8;
  > div {
    line-height: 120px;
  }
  .myHeaderImg {
    margin-top: 20px;
    width: 80px;
    height: 80px;
  }
  .myName {
    margin-left: 20px;
    padding-top: 28px;
    font-size: 18px;
    p {
      height: 30px;
      line-height: 30px;
      color: white;
    }
    p:nth-of-type(2) {
      margin-top: 10px;
    }
    img {
      width: 30px;
      margin-right: 10px;
      height: 30px;
      cursor: Pointer;
    }
  }
  .fn {
    margin-left: 20px;
    padding-top: 22px;
    p {
      cursor: Pointer;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding-left: 14px;
      padding-right: 14px;
      border: 1px solid white;
      background: rgba(255, 255, 255, 0.2);
      color: white;
      margin-right: 18px;
      border-radius: 4px;
    }
  }
  .fns {
    img {
      margin-right: 8px;
    }
    p {
      margin-right: 0;
      margin-left: 18px;
    }
  }
}
</style>